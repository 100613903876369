import Page from '.'
import adminPortalRoutes from './admin-portal/adminPortalRoutes'
import developerDocsRoutes from './developer-docs/developerDocsRoutes'
import developerPortalRoutes from './developer-portal/developerPortalRoutes'
import homeRoutes from './home/homeRoutes'
import myAccountRoutes from './my-account/myAccountRoutes'
import quickStartRoutes from './quick-start/quickStartRoutes'
import techSolutionRoutes from './tech-solution/techSolutionRoutes'
import activitiesManagementRouters from './activitiesManagement/activitiesManagementRouters'
import developmentManagementRouters from './developmentManagement/developmentManagementRouters'
import systemMonitoringRoutes from './system-monitoring/systemMonitoringRoutes'
import systemConfigurationRoutes from './system-configuration/systemConfigurationRoutes'
import usersManagementRoutes from './users-management/usersManagementRoutes'
import principalAccountManagementRouters from './principal-account-management/principalAccountManagementRouters'
import myAgencies from './myAgencies/myAgenciesRouters'

const pageRoutes = [
  {
    path: '/',
    element: Page,
    isProtected: false,
    children: [
      ...homeRoutes,
      ...adminPortalRoutes,
      ...developerDocsRoutes,
      ...developerPortalRoutes,
      ...myAccountRoutes,
      ...quickStartRoutes,
      ...techSolutionRoutes,
      ...activitiesManagementRouters,
      ...developmentManagementRouters,
      ...systemMonitoringRoutes,
      ...systemConfigurationRoutes,
      ...usersManagementRoutes,
      ...principalAccountManagementRouters,
      ...myAgencies,
    ],
  },
]

export default pageRoutes
