import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import IconLogo from '../../assets/images/logo-propeller-full.svg'
import Avatar from '../../assets/images/avatar-example.svg'
import ButtonSignIn from '../sign-in'
import ButtonSignOut from '../sign-out'
import { RootState } from '../../store'
import { RoleType } from '../../constants/interface'
import { KIND, ROLE } from '../../constants/common'
import {
  closePrincipalAccountDetail,
  clearPrincipalAccountInfoDetail,
  clearPrincipalAccountDetail,
  clearCountSearchPrincipalAccount,
} from '../../redux/actions/adminPortal'
import { useWindowSize } from '../../helpers/useWindowSize'
import iconDownArrow from '../../assets/images/icon-down-arrow.svg'
import iconUpArrow from '../../assets/images/icon-up-arrow.svg'
import { screens } from '../../constants/screens'

const Header = () => {
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const dropDownMenuRef = useRef<HTMLUListElement>(null)
  const [isPopupDropDown, setIsPopupDropDown] = useState<boolean>(false)
  const btnRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [isPopup, setIsPopup] = useState<boolean>(false)
  const [isActiveDropDown, setIsActiveDropDown] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<string>(Avatar)

  const accessTokenGg = localStorage.getItem('accessTokenGg')
  const isRoleAdmin = ROLE[userInfoState?.role?.name as keyof RoleType] === ROLE.Admin
  const isRoleDeveloper = ROLE[userInfoState?.role?.name as keyof RoleType] === ROLE.Developer
  const isRoleDeveloperAccountManager = userInfoState?.role?.name === ROLE.DeveloperAccountManager
  const isRoleAccountManager = userInfoState?.role?.name === ROLE.AccountManager

  const dispatch = useDispatch()

  const windowSize = useWindowSize()
  const handleClick = () => {
    setIsPopup(!isPopup)
  }

  const handleClickDropDown = () => {
    setIsPopupDropDown(!isPopupDropDown)
  }

  const handleClickActiveDrop = () => {
    setIsActiveDropDown(true)
    setIsPopupDropDown(false)
  }

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        menuRef &&
        !menuRef.current?.contains(e.target) &&
        btnRef &&
        !btnRef.current?.contains(e.target)
      ) {
        setIsPopup(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Remove click outside
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isPopup])

  useEffect(() => {
    const handleOutsideDropDownClick = (e: any) => {
      if (
        dropDownMenuRef &&
        !dropDownMenuRef.current?.contains(e.target) &&
        btnRef &&
        !dropDownRef.current?.contains(e.target)
      ) {
        setIsPopupDropDown(false)
      }
    }

    document.addEventListener('click', handleOutsideDropDownClick)

    return () => {
      document.removeEventListener('click', handleOutsideDropDownClick)
    }
  }, [isPopupDropDown])

  useEffect(() => {
    setAvatar(userInfoState?.avatar)
  }, [userInfoState])

  // handle clear principal account's data when navigate with admin role and home page
  const handleClearInfoPrincipalAccount = () => {
    handleClickActiveDrop()
    dispatch(closePrincipalAccountDetail())
    dispatch(clearPrincipalAccountInfoDetail())
    dispatch(clearPrincipalAccountDetail())
    dispatch(clearCountSearchPrincipalAccount())
  }

  return (
    <header className="w-full max-w-[1440px] flex items-center px-20 h-[72px] justify-between border-b border-b-neutral-6 bg-neutral-7-header-footer fixed z-20">
      <div className="flex items-center h-full">
        <NavLink to="/" onClick={handleClearInfoPrincipalAccount}>
          <img src={IconLogo} alt="Logo" className="max-w-[110px] h-[40px] mr-5 md:ml-[-40px]" />
        </NavLink>
        <ul className="flex h-full text-body1 text-neutral-4 pl-[48px] md:pl-[28px]">
          {Number(windowSize.width) > screens.md && (
            <>
              {accessTokenGg && (isRoleDeveloper || isRoleDeveloperAccountManager) && (
                <>
                  <li
                    onClick={() => setIsActiveDropDown(false)}
                    className="flex items-center mx-3 relative hover:text-primary-shade2 md:max-w-[200px]"
                  >
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/activities-management"
                    >
                      Activities management
                    </NavLink>
                  </li>
                  <li
                    onClick={() => setIsActiveDropDown(false)}
                    className="flex items-center mx-3 relative hover:text-primary-shade2 md:max-w-[200px]"
                  >
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/development-management"
                    >
                      Development management
                    </NavLink>
                  </li>
                  {userInfoState?.kind === KIND.AGENCIES && (
                    <li
                      onClick={() => setIsActiveDropDown(false)}
                      className="flex items-center mx-3 relative hover:text-primary-shade2 md:max-w-[200px]"
                    >
                      <NavLink
                        className={({ isActive }) =>
                          `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                            isActive && 'before:w-full text-primary-shade1'
                          }`
                        }
                        to="/my-agencies"
                      >
                        My Agencies
                      </NavLink>
                    </li>
                  )}
                </>
              )}
              {accessTokenGg && isRoleDeveloper && (
                <>
                  {Number(windowSize.width) <= screens.lg && (
                    <>
                      <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                        <NavLink
                          className={({ isActive }) =>
                            `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                              isActive && 'before:w-full text-primary-shade1'
                            }`
                          }
                          to="/my-account"
                        >
                          My account
                        </NavLink>
                      </li>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {Number(windowSize.width) <= screens.md && (
            <>
              {accessTokenGg && (isRoleDeveloper || isRoleDeveloperAccountManager) && (
                <>
                  <li
                    onClick={() => setIsActiveDropDown(false)}
                    className="flex items-center mx-3 relative hover:text-primary-shade2 md:max-w-[200px]"
                  >
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/activities-management"
                    >
                      Activities management
                    </NavLink>
                  </li>
                  {Number(windowSize.width) <= screens.md && (
                    <>
                      <li className="relative py-2 pt-6 pl-3 pr-4">
                        <div
                          ref={dropDownRef}
                          onClick={handleClickDropDown}
                          className={`flex items-center cursor-pointer hover:text-primary-shade2 md:pr-6 rounded-full before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                            isActiveDropDown ? 'before:w-11 text-primary-shade1' : ''
                          }`}
                        >
                          More
                          {isPopupDropDown ? (
                            <img src={iconUpArrow} alt="iconUpArrow" width={20} height={20} />
                          ) : (
                            <img src={iconDownArrow} alt="iconDownArrow" width={20} height={20} />
                          )}
                        </div>

                        {isPopupDropDown && (
                          <ul
                            ref={dropDownMenuRef}
                            className="absolute left-[-4px] md:left-[4px] mt-4 w-[300px] rounded-xl bg-neutral-8 p-3 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
                          >
                            <li className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]">
                              <NavLink
                                onClick={handleClickActiveDrop}
                                className={({ isActive }) =>
                                  ` hover:w-full ${
                                    isActive &&
                                    'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                  }`
                                }
                                to="/development-management"
                              >
                                Development management
                              </NavLink>
                            </li>
                            {userInfoState?.kind === KIND.AGENCIES && (
                              <li
                                onClick={() => setIsActiveDropDown(false)}
                                className="flex items-center mx-3 relative hover:text-primary-shade2 md:max-w-[200px]"
                              >
                                <NavLink
                                  className={({ isActive }) =>
                                    `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                                      isActive && 'before:w-full text-primary-shade1'
                                    }`
                                  }
                                  to="/my-agencies"
                                >
                                  My Agencies
                                </NavLink>
                              </li>
                            )}
                            <li className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]">
                              <NavLink
                                onClick={handleClickActiveDrop}
                                className={({ isActive }) =>
                                  ` hover:w-full ${
                                    isActive &&
                                    'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                  }`
                                }
                                to="/my-account"
                              >
                                My account
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </li>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {Number(windowSize.width) > screens.lg && (
            <>
              {accessTokenGg && isRoleAdmin && (
                <>
                  <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/system-monitoring"
                      onClick={handleClearInfoPrincipalAccount}
                    >
                      System monitoring
                    </NavLink>
                  </li>
                  <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/users-management"
                      onClick={handleClearInfoPrincipalAccount}
                    >
                      Users management
                    </NavLink>
                  </li>
                  <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/system-configuration"
                      onClick={handleClearInfoPrincipalAccount}
                    >
                      System configuration
                    </NavLink>
                  </li>
                </>
              )}
              <li
                className={`${
                  accessTokenGg &&
                  (isRoleAdmin || isRoleAccountManager || isRoleDeveloperAccountManager)
                    ? 'flex'
                    : 'hidden'
                } relative items-center mx-3 hover:text-primary-shade2`}
              >
                <NavLink
                  className={({ isActive }) =>
                    `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                      isActive && 'before:w-full text-primary-shade1'
                    }`
                  }
                  to="/principal-account-management"
                >
                  Principal Account management
                </NavLink>
              </li>
              {accessTokenGg && (
                <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                  <NavLink
                    className={({ isActive }) =>
                      `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                        isActive && 'before:w-full text-primary-shade1'
                      }`
                    }
                    to="/my-account"
                  >
                    My account
                  </NavLink>
                </li>
              )}
            </>
          )}

          {Number(windowSize.width) <= screens.lg && (
            <>
              {accessTokenGg && (
                <>
                  {isRoleAdmin && (
                    <>
                      <li
                        onClick={() => setIsActiveDropDown(false)}
                        className="relative flex items-center mx-3 hover:text-primary-shade2"
                      >
                        <NavLink
                          className={({ isActive }) =>
                            `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                              isActive && 'before:w-full text-primary-shade1'
                            }`
                          }
                          to="/system-monitoring"
                          onClick={handleClearInfoPrincipalAccount}
                        >
                          System monitoring
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setIsActiveDropDown(false)}
                        className="relative flex items-center mx-3 hover:text-primary-shade2"
                      >
                        <NavLink
                          className={({ isActive }) =>
                            `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                              isActive && 'before:w-full text-primary-shade1'
                            }`
                          }
                          to="/users-management"
                          onClick={handleClearInfoPrincipalAccount}
                        >
                          Users management
                        </NavLink>
                      </li>
                    </>
                  )}
                  {Number(windowSize.width) <= screens.lg && (
                    <>
                      <li className="relative py-2 pt-6 pl-3 pr-4">
                        <div
                          ref={dropDownRef}
                          onClick={handleClickDropDown}
                          className={`flex items-center cursor-pointer hover:text-primary-shade2 md:pr-6 rounded-full before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                            isActiveDropDown ? 'before:w-11 text-primary-shade1' : ''
                          }`}
                        >
                          More
                          {isPopupDropDown ? (
                            <img src={iconUpArrow} alt="iconUpArrow" width={20} height={20} />
                          ) : (
                            <img src={iconDownArrow} alt="iconDownArrow" width={20} height={20} />
                          )}
                        </div>

                        {isPopupDropDown && (
                          <ul
                            ref={dropDownMenuRef}
                            className="absolute left-[-4px] md:left-[-162px] mt-4 w-[328px] rounded-xl bg-neutral-8 p-3 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
                          >
                            <li
                              className={`${
                                isRoleAdmin ? 'flex' : 'hidden'
                              } items-center mx-3 relative hover:text-primary-shade2 h-[40px]`}
                            >
                              <NavLink
                                onClick={handleClearInfoPrincipalAccount}
                                className={({ isActive }) =>
                                  ` hover:w-full ${
                                    isActive &&
                                    'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                  }`
                                }
                                to="/system-configuration"
                              >
                                System configuration
                              </NavLink>
                            </li>
                            <li
                              className={`${
                                accessTokenGg &&
                                (isRoleAdmin ||
                                  isRoleAccountManager ||
                                  isRoleDeveloperAccountManager)
                                  ? 'flex'
                                  : 'hidden'
                              } items-center mx-3 relative hover:text-primary-shade2 h-[40px]`}
                            >
                              <NavLink
                                onClick={handleClickActiveDrop}
                                className={({ isActive }) =>
                                  ` hover:w-full ${
                                    isActive &&
                                    'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                  }`
                                }
                                to="/principal-account-management"
                              >
                                Principal Account management
                              </NavLink>
                            </li>
                            {accessTokenGg && (
                              <li className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]">
                                <NavLink
                                  onClick={handleClickActiveDrop}
                                  className={({ isActive }) =>
                                    ` hover:w-full ${
                                      isActive &&
                                      'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                    }`
                                  }
                                  to="/my-account"
                                >
                                  My account
                                </NavLink>
                              </li>
                            )}
                          </ul>
                        )}
                      </li>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </ul>
      </div>
      <div className="flex items-center">
        {accessTokenGg || userInfoState ? (
          <div className="relative">
            <div
              ref={btnRef}
              onClick={handleClick}
              className="flex items-center rounded-full cursor-pointer md:mr-[-42px]"
            >
              <img className="max-w-[40px] h-10 rounded-full" src={avatar} alt="Avatar" />
            </div>
            {isPopup && (
              <div
                ref={menuRef}
                className="absolute right-0 mt-2 md:mr-[-40px] rounded-xl bg-neutral-8 p-3 w-60 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
              >
                <div className="p-2 pb-3 mb-1 border-b text-neutral-3 border-b-neutral-6">
                  <p className="font-semibold text-body1">{`${userInfoState?.firstName} ${userInfoState?.lastName}`}</p>
                  <p className="font-medium text-body3">{userInfoState?.role?.name}</p>
                </div>
                <div className="p-1">
                  <ButtonSignOut
                    className="justify-start hover:bg-transparent"
                    onClosePopup={() => setIsPopup(false)}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <ButtonSignIn />
        )}
      </div>
    </header>
  )
}

export default Header
