import moment from 'moment'
import cryptojs from 'crypto-js'
import { notify } from '../components/toastify'
import { STATUS } from '../constants/messages'
import { Role } from '../routes/roles'
import { ROLE } from '../constants/common'

/**
 * Function for copy item
 * @param value
 * @returns
 */
export const onHandleCopyItem = async (
  value: string,
  messageSuccess: string,
  messageError: string,
) => {
  const checkSecurity = window.isSecureContext
  if (checkSecurity) {
    try {
      await navigator.clipboard.writeText(value)
      notify(messageSuccess, STATUS.INFO)
    } catch (error) {
      notify(`${messageError} : ${error}`)
    }
  } else {
    try {
      await navigator.clipboard.writeText(value)
      notify(messageSuccess, STATUS.INFO)
    } catch (error) {
      const textArea = document.createElement('textarea')
      textArea.value = value
      textArea.style.position = 'fixed'
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      return new Promise((_resolve, reject) => {
        document.execCommand('copy') ? notify(messageSuccess, STATUS.INFO) : reject()
        textArea.remove()
      })
    }
  }
}

/**
 * Handle format date time
 * @param value
 * @return
 */
export const onHandleFormatDate = (value: any) => {
  if (value !== null && value !== undefined) {
    const { hour, minute, second, millisecond } = value.time
    const formatMillisecond = millisecond !== null && millisecond !== '' ? `.${millisecond}` : ''
    const formatDate = moment(value.date).format('yyyy-MM-DD')

    const formatTime = `${hour}:${minute}:${second}${formatMillisecond}`
    const result = `${formatDate} ${formatTime}`

    return result.includes('Invalid') ? null : result
  }
}

/**
 * Handle split name at index @
 * @param username
 * @returns
 */
export const onHandleSplitUserName = (username: string) => {
  if (username.includes('@')) {
    if (username !== undefined && username !== null) {
      return username.substring(0, username.indexOf('@'))
    }
  }
  return username
}

/**
 * Handle change string add text 's' or no
 * @param number  to check > or < 1
 * @param text    text need to update
 * @returns       return updated text
 */
export const HandleChangeString = (number: number, text: string) => {
  if (number > 1) {
    return `${text}s`
  }
  return text
}

export const decryptWebhookPassword = (encryptedText: string) => {
  const { REACT_APP_PRODUCTION_WEBHOOK_SECRET_KEY: webhookSecretKeyENV } = process.env
  try {
    const webhookSecretKey = webhookSecretKeyENV as string
    const bytes = cryptojs.AES.decrypt(encryptedText, webhookSecretKey)
    const originalText = bytes.toString(cryptojs.enc.Utf8)

    return originalText
  } catch (error) {
    throw new Error('Invalid password')
  }
}

/**
 * handle role current user
 * @param role current role of user
 * @return     return role by boolean
 */
export const handleRole = (role: Role) => {
  return {
    isRoleAdmin: ROLE[role] === ROLE.Admin,
    isRoleDev: ROLE[role] === ROLE.Developer,
  }
}
