import { gql } from '@apollo/client'

export const PUSH_READ_RECORDS_ADMIN = gql`
  mutation UpdateLastRecordByAdmin($data: UpdateLastRecordAdminInput!) {
    updateLastRecordByAdmin(data: $data) {
      partyId
      lastRecordRead
    }
  }
`

export const GET_LAST_READ_RECORD_ADMIN = gql`
  query LastRecordByAdmin($data: GetLastRecordAdminInput!) {
    lastRecordByAdmin(data: $data) {
      lastRecord
      lastRecordConfirmed
      unreadRecord
    }
  }
`

export const GET_WEBHOOK_ACTIVE_STATUS = gql`
  query GetWebhookActiveStatus {
    getWebhookActiveStatus
  }
`

export const GET_RETRY_SETTING = gql`
  query GetRetrySetting {
    getRetrySetting {
      timeOut
      try1
      try2
      try3
      value1
      value2
      value3
    }
  }
`

export const SET_RETRY_SETTING = gql`
  mutation SetRetrySetting(
    $timeOut: Int!
    $try1: Int!
    $value1: Int!
    $try2: Int!
    $value2: Int!
    $try3: Int!
    $value3: Int!
  ) {
    setRetrySetting(
      data: {
        timeOut: $timeOut
        try1: $try1
        value1: $value1
        try2: $try2
        value2: $value2
        try3: $try3
        value3: $value3
      }
    )
  }
`

export const HOLD_WEBHOOK = gql`
  mutation HoldWebhook {
    holdWebhook
  }
`

export const FIRE_WEBHOOK = gql`
  mutation FireWebhook {
    fireWebhook
  }
`

export const FILTER_BY_MULTI_COLUMN = gql`
  query FilterByMultiColumnByAdmin($data: SearchAdminInput!) {
    filterByMultiColumnByAdmin(data: $data) {
      activityId
      activityType
      createdAt
      jsonPacket
      userName
      statusResponse
      partyId
      kind
      organizationName
    }
  }
`

export const FILTER_BY_GROUP = gql`
  query FilterByGroupByAdmin($data: FilterForAdminInput!) {
    filterByGroupByAdmin(data: $data) {
      key
      partyId
    }
  }
`

export const LOGOUT_ACTIVITIES = gql`
  mutation LogActivity($data: CreateActivityInput!) {
    logActivity(data: $data)
  }
`

export const GET_NEW_USER_REQUESTS = gql`
  query GetUserRequests {
    getUserRequests {
      userId
      createdAt
      firstName
      lastName
      phoneNumber
      email
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorPhoneNumber
      businessSponsorEmail
      organization {
        id
        name
        kind
      }
      gsuiteUsername
      requesterId
    }
  }
`

export const APPROVE_USER_REQUEST = gql`
  mutation CreateGsuiteUser($data: CreateGsuiteUserInput!) {
    createGsuiteUser(data: $data) {
      lastName
      phoneNumber
      email
      avatar
      gsuiteUsername
      gid
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorEmail
      businessSponsorPhoneNumber
    }
  }
`

export const REJECT_USER_REQUEST = gql`
  mutation RejectRequestAccount($data: RejectAccountInput!) {
    rejectRequestAccount(data: $data) {
      firstName
      lastName
    }
  }
`

export const REJECT_USER_REQUEST_TO_DEVELOPMENT = gql`
  mutation RejectRequestAccount($data: RejectAccountToDevelopmentInput!) {
    rejectRequestAccountToDevelopment(data: $data) {
      firstName
      lastName
    }
  }
`

export const GET_WEB_HOOK_BY_ADMIN = gql`
  query GetWebhookByAdmin($data: GetWebhookForAdminInput!) {
    getWebhookByAdmin(data: $data) {
      url
      webhookId
      username
      password
      isAutoSendWebhook
      webhookHeader {
        webhookHeaderId
        value
        key
        isChecked
      }
    }
  }
`

export const PUSH_WEB_HOOK_BY_ADMIN = gql`
  mutation PushUnreadRecordByAdmin(
    $data: PushWebhookForAdminInput!
    $pushUnreadRecordByAdminId: String!
  ) {
    pushUnreadRecordByAdmin(data: $data, id: $pushUnreadRecordByAdminId) {
      statusCode
      message
    }
  }
`

export const SAVE_URL_WEB_HOOK_BY_ADMIN = gql`
  mutation UpdateWebhookByAdmin($data: UpdateWebhookForAdminInput!, $webhookId: String!) {
    updateWebhookByAdmin(data: $data, webhookId: $webhookId) {
      webhookId
      url
    }
  }
`

export const GET_PARTY_NAME = gql`
  query GetPartyName($kind: kindType!, $limit: Int, $page: Int) {
    getPartyName(kind: $kind, limit: $limit, page: $page) {
      id
      name
      kind
    }
  }
`

export const UPDATE_CARRIER_FOR_USER_REQUEST = gql`
  mutation UpdateUserParty($data: UpdateUserCarrierInput!, $userId: String!) {
    updateUserParty(data: $data, userId: $userId) {
      firstName
      lastName
    }
  }
`

export const UPDATE_WEBHOOK_PASSWORD_BY_ADMIN = gql`
  mutation UpdateWebhookBasicAuthenticationByAdmin(
    $data: UpdateWebhookBasicAuthenticationByAdminInput!
    $webhookId: String!
  ) {
    updateWebhookBasicAuthenticationByAdmin(data: $data, webhookId: $webhookId) {
      username
      password
      webhookId
    }
  }
`
export const GET_API_KEY_BY_ADMIN = gql`
  query ApiKeysByAdmin($data: GetApikeyAdminInput!) {
    apiKeysByAdmin(data: $data) {
      apiKeyId
      name
      accessKey
      status
      createdAt
    }
  }
`
export const GENERATE_KEY_BY_ADMIN = gql`
  mutation AddApiKeyByAdmin($data: CreateApikeyAdminInput!) {
    addApiKeyByAdmin(data: $data) {
      apiKeyId
      name
      accessKey
      status
      createdAt
    }
  }
`
export const CHANGE_STATUS_API_KEY_BY_ADMIN = gql`
  mutation UpdateActiveStatusAndNameByAdmin($data: UpdateApiKeyStatusAndNameAdminInput!) {
    updateActiveStatusAndNameByAdmin(data: $data) {
      apiKeyId
      name
      status
    }
  }
`
export const DELETE_KEY_BY_ADMIN = gql`
  mutation DeleteApiKeyByAdmin($data: DeleteApikeyAdminInput!) {
    deleteApiKeyByAdmin(data: $data) {
      name
    }
  }
`

export const CHANGE_KEY_NAME_BY_ADMIN = gql`
  mutation ChangeKeyName($data: ChangeApiKeyAdminInput!) {
    changeKeyName(data: $data) {
      apiKeyId
      createdAt
      name
      accessKey
      status
    }
  }
`

export const GET_NEW_USER_REQUESTS_PROD = gql`
  query GetUserMoveToProduction {
    getUserMoveToProduction {
      userId
      firstName
      lastName
      phoneNumber
      email
      avatar
      gid
      organization {
        id
        name
        kind
      }
      gsuiteUsername
      requesterId
      kind
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorEmail
      businessSponsorPhoneNumber
      isProduction
      isDeleted
      createdAt
    }
  }
`
export const APPROVE_USER_REQUEST_PROD = gql`
  mutation ApproveMoveToProduction($data: ApproveMoveToProductionInput!) {
    approveMoveToProduction(data: $data) {
      lastName
      phoneNumber
      email
      avatar
      gsuiteUsername
      gid
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorEmail
      businessSponsorPhoneNumber
    }
  }
`
export const GET_USER_LIST = gql`
  query GetUserList($organizationIds: [String], $kind: String) {
    getUserList(organizationIds: $organizationIds, kind: $kind) {
      firstName
      lastName
      userId
      phoneNumber
      email
      gsuiteUsername
      isBlocked
      requesterId
      organization {
        id
        name
        kind
      }
      role {
        name
        roleId
      }
    }
  }
`

export const GET_TOTAL_USER = gql`
  query {
    getTotalTypeUser {
      name
      count
      type
    }
  }
`

export const UPDATE_BLOCK_USER = gql`
  mutation UpdateBlockUser($isBlocked: Boolean!, $userId: String!) {
    updateBlockUser(isBlocked: $isBlocked, userId: $userId) {
      userId
      firstName
      lastName
      isProduction
    }
  }
`
export const UPDATE_BLOCK_ACCESS_USER = gql`
  mutation ModifyUserToAccess($isBlock: Boolean!, $userId: String!) {
    modifyUserToAccess(isBlock: $isBlock, userId: $userId) {
      userId
      firstName
      lastName
      isProduction
    }
  }
`

export const UPDATE_BLOCK_USER_PRODUCTION = gql`
  mutation UpdateBlockUser($isBlocked: Boolean!, $requesterId: String!) {
    updateBlockUser(isBlocked: $isBlocked, requesterId: $requesterId) {
      userId
      firstName
      lastName
      isProduction
    }
  }
`
export const DELETE_USER = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      firstName
      lastName
      isProduction
    }
  }
`
export const DELETE_USER_PRODUCTION = gql`
  mutation DeleteUser($requesterId: String!) {
    deleteUser(requesterId: $requesterId) {
      firstName
      lastName
      isProduction
    }
  }
`
export const GET_CARRIER_INFO = gql`
  query GetPartyWithSponsorAndUsers($data: GetPartyWithSponsorAndUsersInput!) {
    getPartyWithSponsorAndUsers(data: $data) {
      partyId
      users {
        isDeleted
        isBlocked
        kind
        gid
        gsuiteUsername
        email
        phoneNumber
        lastName
        firstName
        userId
        avatar
        requesterId
      }
      businessSponsor {
        businessSponsorPhoneNumber
        businessSponsorEmail
        businessSponsorLastName
        businessSponsorId
        businessSponsorFirstName
      }
    }
  }
`
export const EDIT_DEVELOPER_INFO_BY_ADMIN = gql`
  mutation ModifyProfileInfoByAdmin($data: ModifyProfileInput!, $userId: String!, $roleId: String) {
    modifyProfileInfoByAdmin(data: $data, userId: $userId, roleId: $roleId) {
      userId
      lastName
      firstName
      email
      phoneNumber
    }
  }
`
export const EDIT_SPONSOR_INFO_BY_ADMIN = gql`
  mutation ModifyBusinessSponsorByAdmin(
    $data: ModifyBusinessSponsorInput!
    $businessSponsorId: String!
  ) {
    modifyBusinessSponsorByAdmin(data: $data, businessSponsorId: $businessSponsorId) {
      businessSponsorId
      partyId
      kind
      businessSponsorPhoneNumber
      businessSponsorEmail
      businessSponsorFirstName
      businessSponsorLastName
    }
  }
`
export const GET_SUGGEST_P_ACC = gql`
  query GetSuggestionPrincipalAccount($data: GetSuggestionPrincipalAccountInput!) {
    getSuggestionPrincipalAccount(data: $data) {
      principalAccountId
      principalAccountName
      principalAccountAddressLine1
      principalAccountContactName
      principalAccountPhoneNumber
    }
  }
`
export const GET_SUGGEST_PRINCIPAL = gql`
  query GetSuggestionPrincipal($data: GetSuggestionPrincipalInput!) {
    getSuggestionPrincipal(data: $data) {
      principalId
      principalName
      principalAddressLine1
      principalZipcode
    }
  }
`
export const GROUP_PRINCIPAL_ACCOUNT_TO_PRINCIPAL_ACCOUNT = gql`
  mutation GroupPrincipalAccountToPrincipalAccount(
    $principalAccountIdTo: String!
    $principalAccountIdFrom: String!
  ) {
    groupPrincipalAccountToPrincipalAccount(
      principalAccountIdTo: $principalAccountIdTo
      principalAccountIdFrom: $principalAccountIdFrom
    ) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT = gql`
  mutation GroupPrincipalToPrincipalAccount($principalId: String!, $principalAccountId: String!) {
    groupPrincipalToPrincipalAccount(
      principalId: $principalId
      principalAccountId: $principalAccountId
    ) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const CREATE_AND_GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT = gql`
  mutation CreateAndGroupPrincipalToPrincipalAccount(
    $data: CreatePrincipalAccount!
    $principalId: String!
  ) {
    createAndGroupPrincipalToPrincipalAccount(data: $data, principalId: $principalId) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const SEARCH_PRINCIPAL = gql`
  mutation searchPrincipalByNameAndAddress($data: GetSearchPrincipalInput!) {
    searchPrincipalByNameAndAddress(data: $data) {
      count
      data {
        recordsCount
        agencyName
        principalAccountName
        principalAddressLine1
        principalName
        principalKey
      }
    }
  }
`
export const SEARCH_PRINCIPAL_ACCOUNT = gql`
  mutation Data($data: GetSearchPrincipalAccountInput!) {
    searchPrincipalAccountByNameAndAddress(data: $data) {
      data {
        countPrincipal
        principalAccountBusinessEmail
        principalAccountName
        principalAccountId
        principalAccountAddressLine1
        principalAccountState
        principalAccountPhoneNumber
        principals {
          recordsCount
          principalAddressLine1
          principalAccountId
          agencyName
          principalName
          principalKey
        }
      }
      count
    }
  }
`
export const GET_PRINCIPAL_ACCOUNT_INFO = gql`
  mutation GetPrincipalAccount($data: GetPrincipalAccountInput!) {
    getPrincipalAccount(data: $data) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountCity
      principalAccountAddressLine2
      principalAccountState
      principalAccountZipcode
      countPrincipal
    }
  }
`
export const GET_PRINCIPAL_ACCOUNT_DETAIL = gql`
  mutation GetPrincipalsInPrincipalAccountDetail($data: GetPrincipalByPrincipalAccountInput!) {
    getPrincipalsInPrincipalAccountDetail(data: $data) {
      principalName
      agencyName
      principalKey
      principalAccountId
      principalAddressLine1
      recordsCount
    }
  }
`
export const EDIT_PRINCIPAL_DETAIL = gql`
  mutation UpdatePrincipalAccount(
    $data: UpdatePrincipalAccountInput!
    $principalAccountId: String!
  ) {
    updatePrincipalAccount(data: $data, principalAccountId: $principalAccountId) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
export const GET_CLIENT_CREDENTIAL_ADMIN = gql`
  query GetClientCredentialForAdmin($data: AdminBaseInput!) {
    getClientCredentialForAdmin(data: $data) {
      audience
      Auth0Url
      clientId
      secretKey
    }
  }
`
export const CREATE_CLIENT_CREDENTIAL_ADMIN = gql`
  mutation CreateClientCredentialAdmin($data: CreateClientCredentialAdmin!) {
    createClientCredentialForAdmin(data: $data) {
      audience
      Auth0Url
      clientId
      secretKey
    }
  }
`

export const DELETE_CLIENT_CREDENTIAL_ADMIN = gql`
  mutation DeleteClientCredentialForAdmin($data: AdminBaseInput!) {
    deleteClientCredentialForAdmin(data: $data) {
      audience
      Auth0Url
      clientId
      secretKey
    }
  }
`

export const SET_AUTO_SEND_WEBHOOK = gql`
  mutation SetAutoSendWebhook(
    $webhookId: String!
    $isAutoSendWebhook: Boolean!
    $partyId: String
    $kind: String
  ) {
    setAutoSendWebhook(
      webhookId: $webhookId
      isAutoSendWebhook: $isAutoSendWebhook
      partyId: $partyId
      kind: $kind
    )
  }
`

export const SET_WEBHOOK_HEADER_BY_CARRIER = gql`
  mutation SetWebhookHeaderByCarrier($data: [SetWebhookHeaderInput!]!) {
    setWebhookHeaderByCarrier(data: $data)
  }
`

export const SET_WEBHOOK_HEADER_BY_ADMIN = gql`
  mutation SetWebhookHeaderByAdmin($data: SetWebhookHeaderByAdminInput!) {
    setWebhookHeaderByAdmin(data: $data)
  }
`

export const DELETE_WEBHOOK_HEADER_BY_CARRIER = gql`
  mutation DeleteWebhookHeaderByCarrier($webhookHeaderId: String!) {
    deleteWebhookHeaderByCarrier(webhookHeaderId: $webhookHeaderId)
  }
`

export const DELETE_WEBHOOK_HEADER_BY_ADMIN = gql`
  mutation DeleteWebhookHeaderByAdmin($webhookHeaderId: String!) {
    deleteWebhookHeaderByAdmin(webhookHeaderId: $webhookHeaderId)
  }
`

export const GET_AGENCY_BY_AMS_ADMIN = gql`
  query GetAgenciesByAMSAdmin($partyId: String!, $kind: String!) {
    getAgenciesByAMSAdmin(partyId: $partyId, kind: $kind) {
      agencyAmsId
      agencyAmsKey
      agency {
        agencyId
        agencyName
        agencyKey
      }
    }
  }
`

export const GET_AGENCIES_BY_ADMIN = gql`
  query GetAgencies($skip: Int, $take: Int, $agencyName: String!) {
    getAgenciesByAdmin(skip: $skip, take: $take, agencyName: $agencyName) {
      agencyId
      agencyKey
      agencyName
    }
  }
`

export const CREATE_AGENCY_BY_ADMIN = gql`
  mutation Mutation($data: CreateAgencyInput!) {
    createAgency(data: $data) {
      agencyAmsId
    }
  }
`

export const DELETE_AGENCY_BY_ADMIN = gql`
  mutation DeleteAgency($data: DeleteAgencyInput!) {
    deleteAgency(data: $data) {
      agencyAmsKey
    }
  }
`

export const SEARCH_AMS = gql`
  query SearchAMS($agencyManagementProviderName: String!) {
    searchAMS(agencyManagementProviderName: $agencyManagementProviderName) {
      agencyManagementProviderId
      agencyManagementProviderName
      kind
      partyId
    }
  }
`

export const MERGE_AMS = gql`
  mutation UpdateUser($data: UpdateUserInput!, $updateUserId: String!) {
    updateUser(data: $data, id: $updateUserId) {
      organizationName
    }
  }
`

export const ADD_AMS_TO_SYSTEM = gql`
  mutation CreateAMS($agencyManagementProviderName: String!) {
    createAMS(agencyManagementProviderName: $agencyManagementProviderName) {
      agencyManagementProviderId
      agencyManagementProviderName
    }
  }
`

export const GET_ALL_ROLE_BY_KIND = gql`
  query GetAllRoleByKind($kind: KindEnum) {
    getAllRoleByKind(kind: $kind) {
      name
      roleId
    }
  }
`
export const UPDATE_ROLE_FOR_USER = gql`
  mutation UpdateRoleForUser($data: UpdateRoleForUser!) {
    updateRoleForUser(data: $data) {
      role {
        name
        roleId
      }
    }
  }
`
